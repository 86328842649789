@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ag-grid */

.ag-grid {
  padding-bottom: 0.61538rem;
}
.ag-grid ::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

.ag-grid ::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

.ag-grid ::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}

.ag-grid ::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
}

.ag-grid > .ag-body-horizontal-scroll ::-webkit-scrollbar {
  height: 0.6rem !important;
}

.ag-grid > .ag-center-cols-viewport ::-webkit-scrollbar {
  height: 0px;
}

.header-align-center > .ag-header-group-cell-label, .ag-header-cell-label {
  justify-content: center;
}

.cell-align-left {
  display: flex;
  justify-content: left;
  align-items: center;
}

.cell-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-theme-alpine {
  --ag-data-color: #121619;
  --ag-borders: solid 0.5px;
  --ag-border-color: #e5e5e5;
  --ag-borders-row: none;
  --ag-row-border-color: #e5e5e5;
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-background-color: #fff;
  --ag-font-size: 0.92308rem;
  --ag-odd-row-background-color: transparent;
  --ag-selected-row-background-color: #2d2d2d38;
  --ag-row-hover-color: #3333330a;
  --ag-header-height: 42px;
}

.ag-header {
  --ag-header-foreground-color: #fff;
  --ag-header-background-color: #464775;
  border-top: 2px solid #6F707F;
  border-bottom: 1px solid #e5e5e5;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #3F4069;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: transparent;
}

.ag-root-wrapper {
  border-radius: 13px;
}

.ag-row-even{
  background-color: #fff;
}
.ag-row-odd{
  background-color: #F5F7F9 !important;
}

/* 검색영역 */
Button.search, Button.search:hover {
  background-color: #6264A7;
  color:#fff;
  border: none;
}

Button.create, Button.create:hover {
  background-color: #4D585D;/* #e9c36c; */ /* #FDB913; */
  color:#fff;
  border: none;
}

Button.cancel, Button.cancel:hover {
  background: transparent;
  color: #4D585D;
  border: 1px solid #4D585D;
}

Button.delete, Button.delete:hover {
  background-color: #DC6C66;
  color:#fff;
  border: none;
}

Button.detail, Button.detail:hover {
  background-color: #fff;
  color:#606060;
  border: 1px solid #C4C4C4;
}

Button.xsmall{
  height: 1.84615rem;
  font-size: 0.84615rem;
  padding: 0 0.61538rem;
  border-radius: 4px;
}

Button.small{
  height: 2.46153rem;
  font-size: 0.84615rem;
  padding: 0 0.92307rem;
  border-radius: 4px;
}

Button.medium{
  height: 3.07692rem;
  font-size: 1.07692rem;
  padding: 0 1.23076rem;
  border-radius: 4px;
}

/* 모달 관련 */
.common-modal {
  /* IE 10+ */
  -ms-overflow-style: none;

  /* Firefox */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
/* Safari and Chrome */
.common-modal ::-webkit-scrollbar {
  /* display: none; */
}

/* modal 공통 */
.header {
  font-weight: 600;
  font-size: 0.92307rem;
  background: #464775;/* #6264A7; */
  color: #fff;
  padding: 0.61538rem 1.23076rem;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.61538rem 1.23076rem;
  background: #F7F7F7;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.footer > Button:not(:last-child) {
  margin-right: 0.61538rem;
}