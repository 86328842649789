.list-grid-div {
    width: 100%;
    height: 70%;
    padding: 0 1.5rem 0.61538rem 1.5rem;
    background-color: #F7F7F7;
}

.grid-top-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
}

.grid-top-btn {
    display: flex;
    justify-content: space-between;
}

.grid-excel-btn{
    display: flex;
    align-items: center;
    margin: 8px 0 0 8px;
    font-size: 0.92308rem; 
}

.grid-page-size-select-area {
    display: flex;
    align-items: center;
    margin: 8px 0 0 8px;
    font-size: 0.92308rem;
}

.grid-page-size-select-area-btn {
    display: flex;
    align-items: center;
    margin: 8px 0 0 8px;
    font-size: 0.92308rem;
}

.page-size-select-title {
    margin: 0;
    padding: 0 0.25rem 0 0;
}

.grid-page-size-select-area select{
    background: transparent;
}

.work-show-btn {
    height: 2rem;
    width: 4rem;
    font-size: 0.5rem;
}


/*시간기록 생성 모달*/

#create-modal-select-textarea {
    width: 150px;
    height: 100px;
  }
  .issue-dtl-table {
    border-collapse: collapse;
    margin: 15px 10px;
    font-size: 0.8rem;
    font-family: sans-serif;
    min-width: 400px;
    border: 1px solid #e5e5e5;
    border-top: 2px solid #6264A7;
}

.issue-dtl-table thead tr {
    background-color: #E9EAF4;
    /*row #464775*/
    color: #222;
    text-align: left;
    margin: 10px 30px;
    height: 40px;
}

.issue-dtl-table  th,
.issue-dtl-table  td {
    padding: 0.46154rem;
}

.issue-dtl-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.issue-dtl-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.issue-dtl-table tbody tr:last-of-type {
    border-bottom: 2px solid #6264A7;
}

.issue-dtl-table button{
    border-radius: 4px; 
    box-shadow: 0px 1px 2px 1px #afafafbf;
}

.issue-dtl-table button.update, .issue-dtl-table button.update:hover {
    margin-right: 9px;
    background: #92C353;
    color:#fff;
    border: none;
}

.issue-dtl-table button.delete, .issue-dtl-table button.delete:hover {
    background-color: #DC6C66;
    color:#fff;
    border: none;
}

.issue-dtl-td-description, .issue-dtl-tr-description {
    width: 38%;
}

.issue-dtl-td-btn {
    width: 20%;
}

.issue-dtl-td-link:hover {
    color: dodgerblue;
    cursor: pointer;
}

/* cellClassRules Bottom 적용 CSS  */
.cell-rules-rowbottom {
    color: red;
}

.cell-rules-rowbottom-bottom {
    color: blue;
}

.ag-tooltip{
    background-color: #0f1014;
    color: #999eab;
    border-radius: 2px;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #545454;
 }

 iframe#webpack-dev-server-client-overlay{display:none!important}

 /* cellClassRules Middle 적용 CSS  */
 .show-cell {
    background: white;
    border-left: 1px solid lightgrey !important;
    border-right: 1px solid lightgrey !important;
    border-bottom: 1px solid lightgrey !important;
  }

  #select-team-mut{
    display: none;
  }