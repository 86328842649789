.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .list-grid-div {
    width: 100%;
    height: 70%;
    padding: 0 1.5rem 0.61538rem 1.5rem;
    background-color: #F7F7F7;
}

.grid-top-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
}

.grid-top-btn {
    display: flex;
    justify-content: space-between;
}

.grid-page-size-select-area {
    display: flex;
    align-items: center;
    margin: 8px 0 0 8px;
    font-size: 0.92308rem;
}

.grid-page-size-select-area-btn {
    display: flex;
    align-items: center;
    margin: 8px 0 0 8px;
    font-size: 0.92308rem;
}

.page-size-select-title {
    margin: 0;
    padding: 0 0.25rem 0 0;
}

.grid-page-size-select-area select{
    background: transparent;
}

.work-show-btn {
    height: 2rem;
    width: 4rem;
    font-size: 0.5rem;
}


/*시간기록 생성 모달*/

#create-modal-select-textarea {
    width: 150px;
    height: 100px;
  }
  .issue-dtl-table {
    border-collapse: collapse;
    margin: 15px 10px;
    font-size: 0.8rem;
    font-family: sans-serif;
    min-width: 400px;
    border: 1px solid #e5e5e5;
    border-top: 2px solid #6264A7;
}

.issue-dtl-table thead tr {
    background-color: #E9EAF4;
    /*row #464775*/
    color: #222;
    text-align: left;
    margin: 10px 30px;
    height: 40px;
}

.issue-dtl-table  th,
.issue-dtl-table  td {
    padding: 0.46154rem;
}

.issue-dtl-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.issue-dtl-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.issue-dtl-table tbody tr:last-of-type {
    border-bottom: 2px solid #6264A7;
}

.issue-dtl-table button{
    border-radius: 4px; 
    box-shadow: 0px 1px 2px 1px #afafafbf;
}

.issue-dtl-table button.update, .issue-dtl-table button.update:hover {
    margin-right: 9px;
    background: #92C353;
    color:#fff;
    border: none;
}

.issue-dtl-table button.delete, .issue-dtl-table button.delete:hover {
    background-color: #DC6C66;
    color:#fff;
    border: none;
}

.issue-dtl-td-description, .calendar-issue-dtl-tr-description {
    width: 27%;
}

.issue-dtl-td-btn {
    width: 20%;
}

.issue-dtl-td-link:hover {
    color: dodgerblue;
    cursor: pointer;
}

/* cellClassRules Bottom 적용 CSS  */
.cell-rules-rowbottom {
    color: red;
}

.ag-tooltip{
    background-color: #0f1014;
    color: #999eab;
    border-radius: 2px;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #545454;
 }


 .fc .fc-timegrid-slot-label { height: 2em; width : 50em }

 
/* tooltip yellow 테마 제작 */ 
.tippy-box[data-theme~='yellow'] { background-color: #fffadf; color: black; } 


/* view 화면 사이즈 조절heigth 800px 고정 말고 다른 방향으로 체크 필요*/
.fc .fc-view-harness { height: 650px !important}



/* 스크롤 height */
.fc .fc-scrollgrid-liquid {
    height: 98%;
}

.fc.fc-timegrid-day-frame {
    height: 10px;
 }

 /* event 내 텍스트 넘치는 경우 overflow hidden 처리*/
 .fc-time-grid-event .fc-content {
    overflow: hidden;
    max-height: 100%;
 }

 /* 마우스 오버 시 현재 시간 표기*/
  .current-time {
    background-color: rgba(20, 20, 20, 0.10);
    color: #181818;
    position: relative;
    cursor: pointer;
    /*padding-right: 5px;*/
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items : center;
    height: 2.5em;
}


/* 현재 시간 선 표기 */
.fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before {
    position: absolute;
    display: block;
    content: '';
    margin-top: -7px;
    width: 13px;
    height: 13px;
    border-radius: 13px;
    border: 1px solid var(--reactist-bg-default);
    background-color: var(--fc-now-indicator-color);
    left: calc(var(--fc-now-indicator-shift) - 3px);
}.fc-timeGridWeek-view .fc-timegrid-now-indicator-container {
    overflow: visible !important;
}
.fc-timeGridWeek-view .fc-timegrid-now-indicator-line {
    width: calc(700% + 6px) !important;
}

/**
 * The use of nth-child is to address the day columns depending on their order.
 *
 * - The 1st column does not correspond to any day, but it's the column that shows the timegrid
 *   timeline.
 * - The 2nd column corresponds to the 1st day of the week view. This is the one with 0% below.
 * - We continue with each subsequent column, incrementing its percentage in 1/7 each time.
 *
 * By addressing day columns by their order, instead of by their weekday class name
 * (e.g. .fc-day-fri) we guarantee that this solution works regardless of what the first day of the
 * week is.
 */
.fc-timeGridWeek-view .fc-day:nth-child(2) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 0%;
}
.fc-timeGridWeek-view .fc-day:nth-child(3) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 14.29%;
}
.fc-timeGridWeek-view .fc-day:nth-child(4) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 28.57%;
}
.fc-timeGridWeek-view .fc-day:nth-child(5) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 42.86%;
}
.fc-timeGridWeek-view .fc-day:nth-child(6) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 57.14%;
}
.fc-timeGridWeek-view .fc-day:nth-child(7) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 71.43%;
}
.fc-timeGridWeek-view .fc-day:nth-child(8) .fc-timegrid-now-indicator-line {
    --fc-now-indicator-shift: 85.71%;
}

.fc-timeGridWeek-view .fc-day .fc-timegrid-now-indicator-line {
    transform: translateX(calc(-1 * var(--fc-now-indicator-shift)));
}

/* 근태시간 확인 및 클릭 버튼*/
.shrs-time-select-btn{
    font-size: '0.84615rem'
}

.shrs-time-select-btn:hover{
    background-color: #6be137
}

.fc-customRequestButton-button {
    border-color:#4D585D;
    background-color: white;
    color: #4D585D;
    border: solid;
    width: 100px;
  } 
  .fc-customRequestButton-button:hover {
    border-color:#4D585D;
    background-color: white;
    color: #4D585D;
    border: solid;
  } 

  .fc-customRequestButton-button:focus {
    border-color:#4D585D;
    background-color: white;
    color: #4D585D;
    border: solid;
  } 

  .fc-customHelpButton-button {
    border-color:#ff0000;
    background-color: white;
    color: #ff0000;
    border: solid;
    width: 100px;
  } 
  .fc-customHelpButton-button:hover {
    border-color:#ff0000;
    background-color: white;
    color: #ff0000;
    border: solid;
  } 

  .fc-customHelpButton-button:focus {
    border-color:#ff0000;
    background-color: white;
    color: #ff0000;
    border: solid;
  } 