.search-select-input-area {
    padding: 0.3rem 0.5rem;
    border-bottom: 1.5px solid #babfc750;
}

.search-select-input {
    width: 100%;
    height: 2rem;
    border-radius: 0.28rem;
    border: 2px solid #1565c0;
    font-size: 0.84615rem;
}

.search-select-input:focus {
    outline: none;
}

.search-selected-area {
    padding: 0.2rem 0.5rem;
}

.selected-item-del-area {
    height: max-content;
}

.selected-del-font {
    font-size: 0.84615rem;
    color: #1565c0;
    margin: 0;
}

.selected-del-font:hover {
    text-decoration: underline;
    cursor: pointer;
}

.search-select-list {
    height: 30px;
    font-size: 0.84615rem;
}

.search-select-txt > span {
    font-size: 0.84615rem;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search-select-no-rlt-txt {
    font-size: 0.84615rem;
    margin: 0;
    padding: 0.2rem 1.2rem;
}

.bottom-progress-area {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 이슈 검색 시 종류 버튼*/
.w-btn {
    position: relative;
    border: none;
    display: inline-block;
    padding: 7px 14px;
    margin-right: 15px;
    width: 106px;
    border-radius: 5px;
    font-family: "paybooc-Light", sans-serif;
    text-decoration: none;
    font-weight: 600;
    transition: 0.25s;
}
.w-btn-indigo {
    background-color: aliceblue;
    color: #1e6b7b;
}

.search-w-btn {
    padding: 0.3rem 0.5rem;
}
.btn1 {
    appearance: none;
    width: 1rem;
    height: 1rem;
    background-image:  url("../../images/bestar.png");
    background-size: 100% 100%;
  }
.btn1:checked {
    appearance: none;
    width: 1rem;
    height: 1rem;
    background-image:  url("../../images/star.png");
    background-size: 100% 100%;
  }