:root {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
:root::-webkit-scrollbar {
    display: none;
}

.bodyStyle {
    background-color: #F7F7F7;
    height: 100vh;
}

.searchFilterArea {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.61538rem 0 0 1.5rem;
}

.datePicker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.searchBtnOutBox {
    margin: -1.5rem 0 0 0;
}

.searchBtn {
    width: 7rem;
    height: 3rem;
    border-radius: 5rem;
}

.dtlBtnBox {
    margin: -1.5rem 0 0 0;
}

.dtlBtn {
    height: 56.6px;
    padding: 0;
    margin: 0;
}

.dtlArea {
    width: 88%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.23076rem 0 0 1.5rem;
}

.dtlAreaTitle {
    position: relative;
    top: -2.7rem;
}

.dtlAreaTitleFont {
    width: 2.5rem;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0.7rem;
    color: #999;
    font-size: 0.9rem;
    text-align: center;
}

.midBtnArea {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 1rem;
}

/* createModal */
.createRow {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}
.createRowWrap{
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.createLabel {
    font-size: 0.92307rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}


.createCategory{
    width: 400px;
}

.createFlex{
    flex: 1;
}


.createTextArea{
    font-size: 0.84615rem;
    border-radius: 4px;
    padding-left: 8px;
    background-color: #F7F7F7;
    outline-color:#1974CF;
    border: 1px solid #BEBEBE;
    width: 400px;
}

.createInput{
    font-size: 0.84615rem;
    border-radius: 4px;
    padding-left: 8px;
    background-color: #F7F7F7;
    outline-color:#1974CF;
    width: 400px;
    height: 2.46153rem;
    border: 1px solid #BEBEBE;
}

.createhelper{
    font-size: 0.65rem;
    padding-left: 8px;
    background-color: #F7F7F7;
    outline-color:#1974CF;
    color: rgba(0,0,0,0.6);
    height: 2.46153rem;
    margin-left: 100px;
}

.createIssueInput{
    font-size: 0.84615rem;
    border-radius: 4px;
    padding-left: 8px;
    background-color: #F7F7F7;
    outline-color:#1974CF;
    width: 400px;
    height: 2.46153rem;
    border: 1px solid #BEBEBE;
}

.createcategoryhelper{
    font-size: 0.65rem;
    padding: 8px;
    background-color: #F7F7F7;
    outline-color:#1974CF;
    color: rgba(0,0,0,0.6);
    height: 1.46153rem;
}

/* modal 공통 */

.multiItem {
    display: flex;
    width:400px;
}
.span {
    display: flex;
    align-items: center;
    padding: 0 7px;
}
.modalContent {
    padding: 0 1.23076rem 0.61538rem 1.23076rem;
    overflow-x : hidden;
    height: 550px;
}

/* 근태 코멘트 */
.workComment {
    color: red;

}